// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-book-book-tsx": () => import("./../../../src/templates/Book/Book.tsx" /* webpackChunkName: "component---src-templates-book-book-tsx" */),
  "component---src-templates-protocol-protocol-tsx": () => import("./../../../src/templates/Protocol/Protocol.tsx" /* webpackChunkName: "component---src-templates-protocol-protocol-tsx" */),
  "component---src-templates-search-search-tsx": () => import("./../../../src/templates/Search/Search.tsx" /* webpackChunkName: "component---src-templates-search-search-tsx" */)
}

